import './App.css';
import Quotes from './components/quotes/quotes';

function App() {
  return (
    <div className="App">
      <Quotes /> 
    </div>
  );
}

export default App;
